* {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  color: black;
  font-size: 1.8rem;
  line-height: 1.6;
  overflow: auto;
}
h1 {
  font-size: 4.5rem;
}
h2 {
  font-size: 3.5rem;
}
h3 {
  font-size: 2rem;
}
h4 {
  font-size: 1.75;
}
p {
  font-size: 1.6rem;
}
a {
  text-decoration: none;
}
.bold {
  font-weight: 700;
}
.backgroundImage {
  width: 90%;
  height: 90vh;
  position: fixed;
  background-size: cover;
  background-position: center;
  z-index: -1;
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: 'Roboto', sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.landing-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2%;
  width: 100%;
  height: calc(100vh - 75px);
}
@media (max-width: 500px) {
  .landing-container {
    flex-direction: column;
    height: calc(100vh - 60px);
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .landing-container {
    flex-direction: column;
    height: calc(100vh - 80px);
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .landing-container {
    flex-direction: column;
  }
}
.text-container {
  height: 93%;
  width: 60%;
  color: #1351a8;
}
.text-container p {
  width: 80%;
  max-width: 600px;
  line-height: 1.2;
  margin: 0 0 3% 1%;
}
@media (max-width: 500px) {
  .text-container p {
    font-size: 0.9rem;
    width: 100%;
    text-align: center;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-container p {
    min-width: 400px;
    width: 50%;
    text-align: justify;
    margin: 2% 0;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .text-container p {
    width: 50%;
    text-align: justify;
    margin: 2% 0;
  }
}
.text-container button {
  background: #1351a8;
  border: none;
  color: #ffffff;
  width: 375px;
  font-size: 2.5rem;
  border-radius: 10px;
  padding: 2%;
  margin: 2% 0 0 1%;
  font-weight: bold;
}
.text-container button:hover {
  background: #2f73d4;
  cursor: pointer;
}
@media (max-width: 500px) {
  .text-container button {
    padding: 5%;
    width: 200px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-container button {
    font-size: 1.55rem !important;
    padding: 2%;
    margin: 1% 0 0 0;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .text-container button {
    padding: 2%;
    margin: 1% 0 0 0;
  }
}
@media (max-width: 1400px) {
  .text-container button {
    font-size: 1.85rem;
  }
}
@media (max-width: 500px) {
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    margin-bottom: 30px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 420px;
    margin-bottom: 50px;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 420px;
    margin-bottom: 50px;
  }
}
.text-content {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  min-width: 300px;
  margin: 0;
}
.text-content h1 {
  min-width: 400px;
  font-size: 3.9rem;
  font-weight: 1000;
  margin: 0.5%;
}
@media (max-width: 500px) {
  .text-content h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-content h1 {
    font-size: 2.2rem;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .text-content h1 {
    font-size: 2.5rem;
  }
}
.text-content h2 {
  min-width: 400px;
  font-size: 2.2rem;
  font-weight: 650;
  margin: 0.5% 0.5% 1.5% 0.8%;
}
@media (max-width: 500px) {
  .text-content h2 {
    font-size: 1.2rem;
    text-align: center;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-content h2 {
    font-size: 1.33rem;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .text-content {
    width: 50%;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .text-content {
    width: 50%;
  }
}
.img-container {
  width: 50%;
  height: 100%;
  max-height: calc(100vh - 150px);
}
.img-container img {
  object-fit: contain;
  width: 100%;
  max-height: 100%;
}
.img-container h2 {
  color: #1351a8;
  text-align: center;
  font-size: 2rem;
  font-weight: 650;
  margin: 0.5% 0.5% 1.5% 0.5%;
}
@media (max-width: 500px) {
  .img-container h2 {
    font-size: 1rem;
    text-align: center;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .img-container h2 {
    font-size: 1.5rem;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .img-container h2 {
    font-size: 1.5rem;
  }
}
@media (max-width: 500px) {
  .img-container {
    width: 90%;
    height: '';
    margin: 0 !important;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .img-container {
    min-width: 400px;
    width: 46%;
    height: '';
    margin: 0 !important;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .img-container {
    min-width: 400px;
    width: 46%;
    height: '';
    margin: 0 !important;
  }
}
.landingLearnMore {
  background: #F0F0F0;
  min-height: calc(100vh - 75px);
  width: 100%;
  padding: 1% 0 2% 0;
}
.learnMoreFlex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  line-height: 1.2;
  padding: 1% 2% 0 2%;
}
.learnMoreFlex h1 {
  color: #1351a8;
  font-size: 2rem;
  font-weight: 1000;
  margin: 0.5%;
}
.learnMoreFlex p {
  color: #1351a8;
  margin: 0 0 10px 20px;
}
.learnMoreText {
  background: #ffffff;
  border: 1px solid lightsteelblue;
  border-radius: 10px;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  width: 48%;
  margin: 0;
}
.learnMoreText a {
  text-decoration: underline;
  color: #1351a8;
}
.learnMoreText a:visited {
  color: #1351a8;
}
.learnMoreText a:hover {
  color: #F06A67;
}
.learnMoreText a:focus {
  color: #F06A67;
}
.learnMoreText a:hover,
.learnMoreText a a:active {
  color: #F06A67;
}
@media (min-width: 500px) and (max-width: 800px) {
  .learnMoreText {
    margin: 0 3% 0 20px;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .learnMoreText {
    margin: 0 3% 0 0;
  }
}
.learnMoreImages {
  background: #ffffff;
  border: 1px solid lightsteelblue;
  border-radius: 10px;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  width: 48%;
  min-width: 300px;
  max-width: 470px;
  margin: 0 0 0 0;
}
.learnMoreImages img {
  border-radius: 10px;
  width: 100%;
}
.learnMoreImages p {
  text-align: center;
  margin: 4px auto;
}
@media (max-width: 500px) {
  .learnMoreImages {
    display: none;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .learnMoreImages {
    display: none;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .learnMoreImages {
    margin: 0 0 0 0;
  }
}
header {
  position: sticky;
  top: 0;
  z-index: 9;
  background: white;
  border-bottom: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  z-index: 100;
  height: 75px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0% 0.8%;
}
header h1 {
  color: #F06A67;
  font-size: 2.8rem;
  font-weight: 800;
  margin: 0;
}
@media (max-width: 500px) {
  header h1 {
    margin: 0 0 0 10px;
    font-size: 1.5rem;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  header h1 {
    font-size: 2rem;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  header h1 {
    font-size: 2rem;
  }
}
header nav {
  min-width: 30%;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
header nav h3 {
  font-size: 1.3rem;
  font-weight: 800;
  color: #1351a8;
  margin: 0 10px;
}
header nav h3:hover {
  color: #F06A67;
}
@media (max-width: 500px) {
  header nav h3 {
    margin: 10px 5px 0 0;
    font-size: 0.75rem;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  header nav h3 {
    font-size: 1rem;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  header nav h3 {
    font-size: 1rem;
  }
}
header nav button {
  width: 110px;
  background: #ffffff;
  padding: 2%;
  border: 3px solid #1351a8;
  color: #1351a8;
  font-size: 1rem;
  font-weight: 501;
}
header nav button:hover {
  background: #F06A67;
  border: 3px solid #F06A67;
  color: #ffffff;
}
@media (max-width: 500px) {
  header nav button {
    padding: 1% 0;
    font-size: 0.75rem;
    width: 80px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  header nav button {
    padding: 1%;
  }
}
@media (max-width: 500px) {
  header nav {
    justify-content: space-between;
    margin: -15px 0 0 10px;
    width: 100%;
    min-width: 300px;
    max-width: 100%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  header nav {
    margin: 0 auto;
    width: 100%;
    min-width: 475px;
    max-width: 100%;
  }
}
@media (max-width: 500px) {
  header {
    justify-content: none;
    margin: 0;
    padding: 0;
    height: 60px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  header {
    height: 80px;
  }
}
.headerLogo {
  display: flex;
}
.headerLogo h4 {
  color: #1351a8;
  font-size: 1.1rem;
  font-weight: 1000;
  margin: 24px 0 0 5px;
}
@media (max-width: 500px) {
  .headerLogo h4 {
    font-size: 1rem;
    margin: 7px 0 0 5px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .headerLogo h4 {
    font-size: 1rem;
    margin: 14px 0 0 5px;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .headerLogo h4 {
    font-size: 1.1rem;
    margin: 13px 0 0 5px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .headerLogo {
    margin-right: 1000px;
  }
}
.contact {
  display: flex;
  justify-content: space-between;
}
.contactIcon1 {
  margin: 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 500px) {
  .contactIcon1 {
    margin: 0 10px 0 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .contactIcon1 {
    margin: 0 5px 0 0;
  }
}
.contactIcon2 {
  margin: 0 10px 0 10px;
  font-size: 45px;
  color: #7289da;
}
@media (max-width: 500px) {
  .contactIcon2 {
    margin: 0 5px 0 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .contactIcon2 {
    margin: 0 5px 0 0;
  }
}
.modalDiv {
  max-width: 30%;
}
/* Style the Image Used to Trigger the Modal */
.modalImg {
  border-radius: 5px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  transition: 0.3s;
}
.modalImghover {
  opacity: 0.7;
}
/* The Modal (background) */
.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 10;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.9);
  /* Black w/ opacity */
}
/* Modal Content (Image) */
.modal-content {
  margin: auto;
  display: block;
  width: 95%;
}
/* Caption of Modal Image (Image Text) - Same Width as the Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}
/* Add Animation - Zoom in the Modal */
.modal-content,
#caption {
  animation-name: zoom;
  animation-duration: 0.6s;
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
/* The Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.closehoverfocus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
.formDash {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.formContainer {
  border: 1px solid lightsteelblue;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  margin: 2% auto;
  background: #ffffff;
  border-radius: 10px;
  width: 50%;
  display: flex;
  flex-direction: column;
}
.formContainer h3 {
  color: #F06A67;
  font-size: 30px;
  padding: 1%;
  margin: 1% 3%;
}
@media (max-width: 500px) {
  .formContainer {
    width: 85%;
    margin-top: 18%;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .formContainer {
    width: 80%;
    margin-top: 20%;
  }
}
.formInputs {
  width: 90%;
  margin: 0 auto;
}
.formInputs input {
  padding: 2%;
  border: 1px solid #1351a8;
  border-radius: 10px;
  width: 100%;
  margin: 0 0 1% 0;
  font-weight: bold;
}
.formInputs input::placeholder {
  color: #1351a8;
  font-weight: 500;
  background: #ffffff;
}
.formInputs input:focus {
  outline: none;
  border: 1px solid #F06A67;
  color: #1351a8;
  font-weight: 500;
}
.formInputs textarea {
  width: 100%;
  min-height: 300px;
  resize: none;
}
.formSubmit {
  margin-top: 5%;
  display: flex;
  justify-content: center;
}
.formSubmit button {
  background: #1351a8;
  color: #ffffff;
  padding: 3%;
  width: 50%;
  border-radius: 8px;
  font-weight: bold;
  border: #1351a8;
  margin-bottom: 4%;
}
.formSubmit button:hover {
  background: #114791;
  border: #114791;
}
@media (min-width: 500px) and (max-width: 800px) {
  .formSubmit button {
    padding: 5%;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .formSubmit button {
    width: 100%;
    padding: 4%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .formSubmit {
    flex-direction: column-reverse;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .formSubmit {
    width: 100%;
  }
}
.formCard {
  border: 1px solid lightsteelblue;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  margin: 1% 0;
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
}
.formCard h3 {
  color: #F06A67;
  font-size: 30px;
  margin: 0 0 8px 0;
}
@media (max-width: 500px) {
  .formCard h3 {
    font-size: 20px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .formCard h3 {
    font-size: 25px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .formCard h3 {
    font-size: 27px;
  }
}
.formCard p {
  margin: 0 0 8px 8px;
}
@media (max-width: 500px) {
  .formCard {
    width: 98%;
    margin: 5px auto;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .formCard {
    width: 95%;
    margin: 5px auto;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .formCard {
    width: 90%;
    margin: 5px auto;
  }
}
.viewAllForms {
  width: 70%;
  z-index: 2;
  margin: 2% 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 500px) {
  .viewAllForms {
    width: 92%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .viewAllForms {
    width: 95%;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .viewAllForms {
    width: 90%;
  }
}
.mediaDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
}
#dashViewJob {
  position: sticky;
  top: 130px;
  width: 53.5%;
  min-width: 0;
  max-height: 90vh;
  overflow-y: auto;
  margin: -50px 0 0 0;
}
.viewJob-container {
  background: #ffffff;
  box-shadow: 4px 4px rgba(119, 118, 118, 0.2);
  border: 1px solid lightsteelblue;
  border-radius: 10px;
  width: 60%;
  min-width: 850px;
  margin: 3% auto;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.viewJob-container h1 {
  color: #F06A67;
  font-size: 30px;
  margin: 20px 0 0 -10px;
}
.viewJob-container h2 {
  color: #F06A67;
  font-size: 25px;
  margin: 10px 0 10px -10px;
}
.viewJob-container h3 {
  color: #F06A67;
  font-size: 20px;
  margin: 10px 0 10px -10px;
}
@media (max-width: 500px) {
  .viewJob-container {
    width: 90%;
    min-width: 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .viewJob-container {
    width: 88%;
    min-width: 0;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .viewJob-container {
    width: 86%;
    min-width: 0;
  }
}
.viewJob-Title {
  display: flex;
  justify-content: space-between;
}
.viewJob-Links {
  border: '1px solid red';
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 50px;
  padding-top: 20px;
}
.viewJob-Links img {
  margin-top: 6.25px;
  max-width: 43.75px;
  align-self: center;
}
.background {
  width: 100%;
  height: 90vh;
  position: fixed;
  background-size: cover;
  background-position: center center;
  background-color: white;
  z-index: -1;
}
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  position: relative;
  z-index: 99;
}
.redCircle {
  border: 1px solid darkred;
  background: radial-gradient(hsla(0, 100%, 78%, 0.75), #F06C69);
  border-radius: 50%;
  animation: alterBubble 25s infinite alternate ease;
  z-index: -2;
}
.yellowCircle {
  border: 1px solid goldenrod;
  background: radial-gradient(hsla(42, 99%, 60%, 0.75), #FED36D);
  border-radius: 50%;
  animation: alterBubble 25s infinite alternate ease;
  z-index: -2;
}
.redRing {
  border: 1px solid darkred;
  background: radial-gradient(circle at 50%, transparent, transparent 60%, #F06C69 25%);
  border-radius: 50%;
  animation: alterBubble 25s infinite alternate ease;
  z-index: 100;
}
.yellowRing {
  border: 1px solid goldenrod;
  background: radial-gradient(circle at 50%, transparent, transparent 60%, #FED36D 25%);
  border-radius: 50%;
  animation: alterBubble 25s infinite alternate ease;
  z-index: 100;
}
.loginCircle {
  min-width: 600px;
  height: 600px;
  margin: -24% 10% 0 0;
}
.loginRing {
  min-width: 500px;
  height: 500px;
  margin: 0 0 -55% 10%;
}
.registerCircle {
  min-width: 600px;
  height: 600px;
  margin: -24% 0 0 10%;
}
.registerRing {
  min-width: 500px;
  height: 500px;
  margin: 0 10% -55% 0;
}
@keyframes alterBubble {
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.login-container h3 {
  color: #F06A67;
  font-size: 30px;
  padding: 1%;
  margin: 3%;
}
.login-container form {
  width: 100%;
}
@media (max-width: 500px) {
  .login-container form {
    max-width: 90%;
  }
}
.form-inputs-login {
  width: 80%;
  margin: 0 auto;
}
.form-inputs-login input {
  padding: 2%;
  border: 1px solid #1351a8;
  border-radius: 10px;
  width: 100%;
  margin: 3.5% auto;
  font-weight: bold;
}
.form-inputs-login input::placeholder {
  color: #1351a8;
  background: #ffffff;
  font-weight: 500 !important;
}
.form-inputs-login input:focus {
  outline: none;
  border: 1px solid #F06A67;
  color: #1351a8;
  opacity: 1;
}
.remember-and-submit-login {
  margin-top: 5%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #1351a8;
}
.remember-and-submit-login button {
  background: #1351a8;
  color: #ffffff;
  padding: 3%;
  margin-bottom: 4%;
  width: 60%;
  border-radius: 8px;
  font-weight: bold;
  border: #1351a8;
}
.remember-and-submit-login button:hover {
  background: #114791;
  border: #114791;
}
@media (max-width: 500px) {
  .remember-and-submit-login {
    max-width: 95%;
  }
}
.remember-me-login {
  padding: 1%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.remember-me-login input {
  color: #1351a8;
  background: orange;
  border: #1351a8;
}
.remember-me-login label {
  color: #1351a8;
  font-size: 1.25rem;
}
.google-login {
  background: #ffffff;
  color: #1351a8;
  padding: 3%;
  border-radius: 8px;
  font-weight: bold;
  border: 1px solid #1351a8;
  width: 60%;
}
.google-login:hover {
  background: #fafafa;
  color: #F06A67;
  border: 1px solid #1351a8;
}
.return-login {
  font-size: 12px;
  color: #1351a8;
  margin-top: 4%;
  margin-bottom: 4%;
  font-weight: bold;
}
.return-login:hover {
  color: #114791;
  cursor: pointer;
}
.registerParent {
  border: 1px solid lightsteelblue;
  box-shadow: 4px 4px rgba(0, 0, 0, 0.2);
  margin: 8% auto 0 auto;
  background: #ffffff;
  border-radius: 10px;
  width: 40%;
}
@media (max-width: 500px) {
  .registerParent {
    width: 85%;
    margin-top: 18%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .registerParent {
    width: 70%;
  }
}
@media (min-width: 801px) and (max-width: 1024px) {
  .registerParent {
    width: 50%;
    margin-top: 10%;
  }
}
@media (min-width: 1400px) {
  .registerParent {
    width: 30%;
  }
}
.register-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.register-container h3 {
  color: #F06A67;
  font-size: 30px;
  padding: 1%;
  margin: 3%;
}
.register-container form {
  width: 100%;
}
@media (max-width: 500px) {
  .register-container form {
    max-width: 90%;
  }
}
.register-container button {
  background: #1351a8;
  color: #ffffff;
  border: #1351a8;
  border-radius: 8px;
  font-weight: bold;
  width: 50%;
  margin: 0 auto 3% auto;
  padding: 3%;
}
.register-container button:hover {
  background: #114791;
  border: #114791;
}
.form-inputs-register {
  width: 90%;
  margin: 0 auto;
}
.form-inputs-register input {
  padding: 2%;
  border: 1px solid #1351a8;
  border-radius: 10px;
  width: 100%;
  margin: 3.5% auto;
  font-weight: bold;
}
.form-inputs-register input::placeholder {
  color: #1351a8;
  font-weight: 500;
  background: #ffffff;
}
.form-inputs-register input:focus {
  outline: none;
  border: 1px solid #F06A67;
  color: #1351a8;
  font-weight: 500;
}
.register-names {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.register-names input {
  width: 49%;
}
.google-registerDiv {
  border-top: 1px solid lightsteelblue;
  width: 100%;
  display: flex;
  justify-content: space-around;
}
#google-login {
  background: #ffffff;
  color: #1351a8;
  margin: 3% auto;
  padding: 3%;
  border-radius: 8px;
  font-weight: bold;
  border: 1px solid #1351a8;
  width: 50%;
}
#google-login:hover {
  background: #fafafa;
  color: #F06A67;
  border: 1px solid #1351a8;
}
.emailOptIn {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 18.57px;
}
.emailOptIn input {
  margin: 0;
  background: blue;
  width: 20px;
}
.emailOptIn p {
  color: #F06A67;
  font-weight: 550;
  margin: 0 10px;
  min-width: 180px;
}
.emailOptIn span {
  color: #1351a8;
  margin: 0;
}
.registerEmailSent {
  width: 100%;
  min-height: 150px;
  text-align: center;
}
.registerEmailSent h2 {
  padding: 1%;
}
.registerEmailSent button {
  margin-top: 50px;
  cursor: pointer;
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(https://fonts.gstatic.com/s/montserrat/v15/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'), url(https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_c5H3gnD-w.ttf) format('truetype');
}
.lr-parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  height: 100vh;
  margin: -20px 0 50px;
}
.lr-parent-container h1 {
  font-weight: bold;
  margin: 20px 0;
}
.lr-parent-container h2 {
  text-align: center;
}
.lr-parent-container p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 35px;
}
.lr-parent-container span {
  font-size: 12px;
}
.lr-parent-container a {
  color: #333;
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
.lr-parent-container button {
  border: 1px solid #DC5653;
  background-color: #F06A67;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  box-shadow: inset 0 0 20px rgba(220, 86, 83, 0);
  outline: 1px solid;
  outline-color: rgba(220, 86, 83, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.lr-parent-container button:hover {
  border: 1px solid #DC5653;
  box-shadow: inset 0 0 20px rgba(220, 86, 83, 0.5), 0 0 20px rgba(220, 86, 83, 0.2);
  outline-color: rgba(220, 86, 83, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}
.lr-parent-container button:active {
  transform: scale(0.95);
}
.lr-parent-container button:focus {
  outline: none;
}
.lr-parent-container button.ghost {
  background: transparent;
  border-color: #fff;
  border: 0 solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0);
  outline: 1px solid;
  outline-color: rgba(255, 255, 255, 0.5);
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.lr-parent-container button.ghost:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5), 0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}
.lr-container {
  background-color: #fff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  width: 968px;
  max-width: 100%;
  min-height: 480px;
}
.lr-form-container form {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
.lr-form-container input {
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
.lr-social-container {
  margin: 20px 0 20px;
}
.lr-social-container {
  border: 1px solid #DC5653;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.lr-form-container {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  transition: all 0.6s ease-in-out;
}
.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 1;
}
.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}
.lr-overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}
.lr-overlay {
  background: #F06A67;
  background: linear-gradient(to right, #F06A67, #DC5653) no-repeat 0 0 / cover;
  color: #fff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}
.lr-overlay-panel {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 100%;
  width: 50%;
  text-align: center;
  transform: translateX(0);
  transition: transform 0.6s east-in-out;
}
.overlay-right {
  right: 0;
  transform: translateX(0);
}
.overlay-left {
  transform: translateX(-20%);
}
.container-profile {
  display: grid;
  height: 92vh;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 1.5fr 1.2fr 0.8fr;
  grid-template-areas: "nav nav nav nav" "sidebar main main main" "sidebar content1 content2 content3" "sidebar footer footer footer";
  grid-gap: 0.2rem;
}
.profile-section-picture {
  background: #ffffff;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: sidebar;
  border-radius: 10px;
}
.profile-section-picture .upper-profile-container {
  height: 30%;
  background: transparent;
}
.profile-section-picture .lower-profile-container {
  height: 70%;
  background: transparent;
}
.profile-section-about {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: main;
  border-radius: 10px;
}
.profile-section-edu {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: content1;
  border-radius: 10px;
}
.profile-section-skills {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: content2;
  border-radius: 10px;
}
.profile-section-jobs {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: content3;
  border-radius: 10px;
}
.profile-section-icons {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 2px solid #F06C69;
  grid-area: footer;
  border-radius: 10px;
}
@media only screen and (max-width: 500px) {
  .container-profile {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.4fr 2.2fr 1.2fr 1.2fr 1.2fr 1fr;
    grid-template-areas: "nav" "sidebar" "main" "content1" "content2" "content3" "footer";
  }
}
.profile-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  width: 50%;
  margin: 75% 0.5% 0.5% 0.5%;
  padding: 50%;
  padding-top: 50%;
  height: 100%;
}
.profile-top h3 {
  width: 100%;
}
@media (max-width: 500px) {
  .profile-top {
    flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .profile-top {
    flex-direction: column;
    width: 100%;
  }
}
.profileNameDiv {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: -25% 0.5% 0.5% 0.5%;
  padding: 50%;
  height: 5%;
}
.profileNameDiv h3 {
  text-transform: capitalize;
  margin: 0;
  padding: 0;
}
.profileNameDiv p {
  font-style: italic;
  margin: 0;
  padding: 0;
}
.profileNameDiv button {
  width: 50px;
  height: 20px;
  padding: 1%;
  background: #F06C69;
  border: 1px solid #F06C69;
  color: white;
  font-size: 1rem;
  border-radius: 8px;
  margin-top: 0.5rem;
}
.profileNameDiv button:hover {
  background: white;
  color: red;
}
@media (max-width: 500px) {
  .profileNameDiv button {
    height: 100%;
    width: 50%;
    padding: 3%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .profileNameDiv button {
    height: 5%;
    width: 50%;
    padding: 2%;
  }
}
@media (max-width: 500px) {
  .profileNameDiv {
    width: 100%;
    max-width: 100%;
    margin-top: 2.5%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .profileNameDiv {
    width: 50%;
    margin-top: 2%;
  }
}
.profileCard {
  border: 1px solid #d1d5da;
  background: #AC2A38;
  color: black;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0.5%;
  padding: 2%;
  background: #ffffff;
}
.profileCard h3 {
  width: 100%;
}
.profileCard p {
  text-align: left;
  width: 100%;
}
@media (max-width: 500px) {
  .profileCard p {
    text-align: center;
  }
}
@media (max-width: 500px) {
  .profileCard {
    text-align: center;
    width: 75%;
    margin: 2%;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .profileCard {
    width: 70%;
    margin: 1% 0 1% 0;
  }
}
.profile-main-div2 {
  height: 100vh;
  display: flex;
  background: #ffffff;
  justify-content: center;
  align-items: center;
}
.profile-second-main2 button {
  width: 387px;
  height: 48px;
  background: #3073AB;
  border: 1px solid #D3D3D3;
  color: #ffffff;
  font-size: 1rem;
  border-radius: 8px;
  margin-top: 5%;
}
.profile-second-main2 button:hover {
  background: #ffffff;
  color: #3073AB;
}
.profile-make2 {
  text-align: center;
  padding: 2%;
}
.profile-main-form2 {
  border: 1px solid #D6D6D6;
  padding: 2%;
  background: #F9F9F9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 473px;
}
.profile-form-inputs2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  min-height: 600px;
}
.profile-form-inputs2 input {
  width: 387px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #D3D3D3;
  font-size: 1.25rem;
}
.profile-form-inputs2 label {
  color: black;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding: 1%;
  margin-top: 2%;
}
.profile-form-inputs2 button {
  margin-bottom: 4%;
}
.dashboardNavParent {
  position: sticky;
  top: 80px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 30%;
}
@media (max-width: 500px) {
  .dashboardNavParent {
    top: 65px;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .dashboardNavParent {
    top: 85px;
  }
}
.dashboardNav {
  background: white;
  border: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 60%;
  min-width: 500px;
  max-width: 500px;
  height: 40px;
  margin: 5px 0 6px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboardNav a {
  text-decoration: none;
}
.dashboardNav h3 {
  font-size: 1.3rem;
  font-weight: 800;
  color: #1351a8;
  margin: 0 10px;
}
.dashboardNav h3:hover {
  color: #F06A67;
}
@media (max-width: 500px) {
  .dashboardNav h3 {
    font-size: 0.75rem;
    margin: 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .dashboardNav h3 {
    font-size: 1rem;
    margin: 0;
  }
}
@media (max-width: 500px) {
  .dashboardNav {
    width: 90%;
    height: 30px;
    min-width: 300px;
    margin: 0 auto;
    justify-content: space-evenly;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .dashboardNav {
    width: 90%;
    height: 30px;
    margin: 0 auto;
    justify-content: space-evenly;
  }
}
.dashSearchBar {
  background: white;
  border: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 60%;
  min-width: 500px;
  max-width: 500px;
  height: 40px;
  margin: 0 0 0 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.dashSearchBar input {
  height: 30px;
  border: 1px solid lightsteelblue;
}
.dashSearchBar input:focus {
  outline: none;
  border: 1px solid #F06A67;
}
.dashSearchBar button {
  height: 30px;
  border: 1px solid lightsteelblue;
}
.dashSearchBar button:focus {
  outline: none;
  border: 1px solid #F06A67;
}
.dashSearchBar select {
  height: 30px;
  border: 1px solid lightsteelblue;
}
.dashSearchBar select:focus {
  outline: none;
  border: 1px solid #F06A67;
}
@media (max-width: 500px) {
  .dashSearchBar {
    width: 90%;
    height: 30px;
    min-width: 300px;
    margin: 0 auto;
    justify-content: space-evenly;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .dashSearchBar {
    width: 90%;
    height: 30px;
    margin: 0 auto;
    justify-content: space-evenly;
  }
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 35%;
}
.jobCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid lightslategray;
  background-color: #fafafa;
  width: 28%;
  min-width: 250px;
  max-width: 300px;
  max-height: 300px;
  margin-bottom: 20px;
  box-shadow: 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}
.jobCard h3 {
  font-size: 1.3rem;
}
.card-image {
  display: flex;
  width: 18%;
  height: 16%;
  margin: 6%;
}
.card-image img {
  width: 50px;
  height: 50px;
}
.card-text {
  margin: 7px 6% 0 6%;
}
.card-text p {
  margin-bottom: 5%;
}
.card-text p span {
  color: #646464;
}
.jobButtons {
  margin: -10px auto 10px auto;
  display: flex;
  justify-content: space-evenly;
  width: 80%;
}
.jobButtons button {
  color: #AC2A38;
  width: 100px;
  height: 35px;
  border: 1px solid #AC2A38;
  border-radius: 3px;
}
.jobButtons button:hover {
  background: #AC2A38;
  border-radius: 5px;
  color: #ffffff;
}
.goalGraphParent {
  display: flex;
  position: sticky;
  top: 187px;
  z-index: 10;
  width: 30%;
  min-width: 500px;
  max-width: 500px;
}
@media (max-width: 500px) {
  .goalGraphParent {
    top: 132px;
    min-width: 220px;
    margin-left: 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .goalGraphParent {
    top: 155px;
    margin-left: 0;
  }
}
.goalGraphContainer {
  background: white;
  border: 1px solid lightsteelblue;
  box-shadow: 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  width: 60%;
  min-width: 500px;
  max-width: 500px;
  margin: 0 0 0 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  transition: 1s;
}
.goalGraphContainer div {
  width: 100%;
}
@media (max-width: 500px) {
  .goalGraphContainer {
    width: 90%;
    min-width: 220px;
    margin-left: 0;
  }
}
@media (min-width: 500px) and (max-width: 800px) {
  .goalGraphContainer {
    width: 90%;
    margin-left: 0;
  }
}
.goalGraphRow {
  display: flex;
  justify-content: space-evenly;
}
.goalGraphRow p {
  width: 100%;
}
.goalGraphSquare {
  border: 1px solid black;
  width: 20px;
  height: 20px;
}
.goalGraphSquareNull {
  border: 1px solid lightsteelblue;
  width: 20px;
  height: 20px;
}
.goalDiv {
  align-self: center;
  background-color: whitesmoke;
  border: 1px solid lightsteelblue;
  border-left: '';
  min-width: 20px;
  height: 82px;
  margin: auto 0;
  text-align: center;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  transition: 1s;
  cursor: pointer;
}
.qh-JobCard {
  border: 1px solid black;
  width: 100%;
  margin-bottom: 3px;
  line-height: 80%;
  word-wrap: break-word;
  padding: 8px 0;
}
.qh-JobCard p,
.qh-JobCard label,
.qh-JobCard button,
.qh-JobCard textarea {
  margin: 3px 3px 3px 10px;
  padding: 0;
}
.qh-JobCard textarea {
  width: 98.5%;
  height: 400px;
}
.qh-JobCard:hover {
  cursor: pointer;
}
.qh-blocked {
  border: 1px solid maroon !important;
  background-color: #FF9EA2 !important;
}
.qh-saved {
  border: 1px solid #00C642 !important;
  background-color: #D8FFD8 !important;
}
.qh-viewed {
  border: 1px solid steelblue !important;
  background-color: lightsteelblue !important;
}
.qh-ribbon {
  position: absolute;
  top: 0px;
  left: 80%;
  width: 50px;
  height: 22px;
  box-sizing: content-box;
  color: white;
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;
  text-transform: uppercase;
}
.qh-ribbon p {
  margin-top: -3px;
  margin-left: 1px;
}
.qh-ribbon:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
}
.qh-ribbonSaved {
  background: #00C642;
}
.qh-ribbonSaved:after {
  border-bottom: 6px solid #D8FFD8;
}
.qh-ribbonBlocked {
  background: maroon;
}
.qh-ribbonBlocked:after {
  border-bottom: 6px solid #FF9EA2;
}
.qh-ribbonViewed {
  background: steelblue;
}
.qh-ribbonViewed:after {
  border-bottom: 6px solid lightsteelblue;
}
.qh-toolbar {
  border: 2px solid #ec8e00;
  border-radius: 8px;
  background-color: #FFDE71;
  display: flex;
  justify-content: space-between;
  width: 94%;
  max-width: 600px;
  margin: 10px auto 0 auto;
  padding: 3px;
}
@media (min-width: 801px) and (max-width: 1024px) {
  .qh-toolbar {
    width: 100%;
  }
}
.qh-toolbar-left {
  width: 60%;
  margin: 0;
  padding: 3px;
}
.qh-toolbar-left p {
  margin: 0;
  padding: 0;
  line-height: 25px;
}
.qh-toolbar-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40%;
  min-width: 130px;
  max-width: 200px;
  margin: 0 3px;
  padding: 3px;
}
.qh-toolbar-right button {
  margin: 0;
  max-height: 25px;
}
.qh-toolbar-right button:hover {
  cursor: pointer;
}
.qh-HideToolbar {
  overflow: hidden;
  height: 0;
  opacity: 0;
  transition: height 0ms 400ms, opacity 400ms 0ms;
}
.qh-ShowToolbar {
  height: auto;
  opacity: 1;
  transition: height 0ms 0ms, opacity 600ms 0ms;
}
